@import '../../../Utils/Sass/GlobalColor.scss';
@import '../../../Utils/Sass/GlobalStyle.scss';
.FormHolder{
    width: 100%;
    display: flex;
    align-items: center;
    .TextBoxHolder{
        width: 90%;
        border: none;
        direction: rtl;
        min-height: 3rem;
        max-height: 15rem;
        background: rgb(238, 238, 238);
    }
    label{
        padding: 0.5rem 1rem;
        background: $GoldColor;
        color: #fff;
        font-size: 1.3rem;
        margin:  0 0.3rem;
        border-radius: 0.3rem;
        box-shadow: 0 2px 3px rgb(190, 190, 190);
    }
    .ButtonHolder{
        padding:0.8rem 1rem;
        color: #fff;
        font-size: 1.3rem;

        @include centerValue;
        background: rgb(57, 192, 255);
        border: none;
    }
}