@import "../../../Utils/Sass/GlobalColor.scss";
.ChatListHolder{
    width: 100%;
    flex-direction: column;
    max-height: 70vh;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    li{
        margin: 1rem 0;
        padding: 0.7rem;
        p{
            margin: 0.5rem 0 1rem 0;
            line-height: 1.5rem;

        }
        .FileHolder{
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
        }
        span{
            p{
                font-weight: 600;
                margin: 0;
                display: flex;
                align-items: center;
            }
        }
        border-radius: 0.5rem;
    }
    .AdminLIHolder{
        background: rgb(218, 246, 255);
    }
    .UserLIHolder{
        background: #fff9da;
    }
}
