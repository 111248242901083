@import '../../../Utils/Sass/GlobalColor.scss';
.TabelHolder{
    width: 95%;
    background: #fff;
    overflow-x: scroll;
}
.TabelHolder ul{
    direction: ltr;
}
.TabelHolder table{
    direction: rtl!important;
    min-width: 700px;
    overflow-x: scroll;
}
.TabelHolder table th{
    text-align: right;
}
.TabelHolder table th div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.TabelHolder table th div span{
    text-align: right;
}
.TabelHolder table td{
    direction: rtl!important;
    text-align: right;
}
.TabelHolder table th{
    background: #fff;
}
.TabelHolder table tr:nth-child(2n+1){
    background: rgb(235, 235, 235);
}
.TabelHolder table td:nth-child(3){
    color: green;
}
.TabelHolder table td:nth-child(4){
    color: $LightRed;
}
.TabelHolder table td:nth-child(1){
    color: $BlueColor;
}
.LinkEdit{
    background: rgb(255, 188, 63);
    color: #fff;
    text-decoration: none;
    box-sizing: border-box;
    padding: 0.2rem 0.5rem;
}