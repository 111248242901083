@import '../../../Utils/Sass/GlobalColor.scss';
@import '../../../Utils/Sass/GlobalStyle.scss';
.FormHolder{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    box-sizing: border-box;
    padding: 1rem;
    h3{
        width: 100%;
        text-align: right;
        font-size: 1rem;
        color: $GoldColor;
    }
    .InputHolder{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0;
        .EachBlock{
            width:100%!important;
            border-bottom: 1px dashed rgb(230, 195, 0);
            box-sizing: border-box;
            padding: 1rem 0;
            label{
                margin-top: 0.5rem;
            }
        }
        li{
            width: 260px;
            margin: 0.5rem;
            p{
                font-size: 0.8rem;
            }
        }
        .ImagePerview{
            width: 320px!important;
            height: 200px !important;
            object-fit: cover;
            margin: 0.5rem 0;
        }

    }

    .ImagePart{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .ImageLabel{
            background: $GoldColor;
            color:#fff;
            height: 2rem;
            @include centerValue;
            box-sizing: border-box;
            padding: 0.5rem;
            box-shadow: 0 2px 3px rgb(199, 199, 199);
            border-radius: 0.5rem;
            margin: 0.5rem;
        }

    }
    .DescHolder{
        width: 100%;
        margin-bottom: 1rem;
        textarea{
            height: 10rem;
        }
    }
}
@media (min-width:999px) {
    .InputHolder{
        justify-content: flex-start!important;
    }
    .ImagePerview{
        width: 60%!important;
        height: 430px!important;
    }
}