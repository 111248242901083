@import '../../../Utils/Sass/GlobalColor.scss';
@import '../../../Utils/Sass/GlobalStyle.scss';
.FormHolder{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    box-sizing: border-box;
    padding: 1rem;
    h3{
        width: 100%;
        text-align: right;
        font-size: 1rem;
        color: $GoldColor;
    }
    .InputHolder{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0;
        li{
            width: 260px;
            margin: 0.5rem;
            p{
                font-size: 0.8rem;
            }
        }

    }


}
