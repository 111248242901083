.ReactQuill p{
    font-family: iran-sans-num!important;
    text-align: right;
}
.ReactQuill div{
    direction: rtl;
    text-align: right;
    min-height: 20rem;
    font-family: iran-sans-num;
}
.ReactQuill{
    margin-bottom: 1rem;
}