@import '../../../Utils/Sass/GlobalColor.scss';
.LabelImages{
    background: $GoldColor;
    color: #fff;
    box-sizing: border-box;
    padding: 0.5rem;
    width: 7.2rem;
    box-shadow: 0 2px 3px rgb(177, 177, 177);
    border-radius: 0.2rem;
    margin: 0.5rem 0;
}