@import '../../../Utils/Sass/GlobalColor.scss';
@import '../../../Utils/Sass/GlobalStyle.scss';
.formHolder{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    box-sizing: border-box;
    padding: 1rem;
    h3{
        width: 100%;
        text-align: right;
        font-size: 1rem;
        color: $GoldColor;
    }
    .InputHolder{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0;
        margin-bottom: 1rem;
    }

}
