@import '../../Utils/Sass/GlobalColor.scss';
@import '../../Utils/Sass/GlobalStyle.scss';
.SectionHolder{
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 1rem;
    direction: rtl;
    position: relative;
    .SideBarHolder{
        width: 100%;
        position: fixed;
        transition: all  0.5s  ease-in-out;
        z-index: 3;
        top: 0;
        left: 0;
        transform: translateX(-130%);
        opacity: 0;
    }
    .ActiveSideBarHolder{
        width: 100%;
        position: fixed;
        transition: all  0.5s  ease-in-out;
        z-index: 3;
        top: 0;
        left: 0;
        transform: translateX(0)!important;
        opacity: 1;
    }
    .ContentHolder{
        width: 100%!important;
        margin-top: 3rem;
        .OpenSideBar{
            font-size: 1.5rem;
            @include centerValue;
            background: $GoldColor;
            color: #fff;
            height:3rem;
            width: 3rem;
            z-index: 3;
            position: absolute;
            top:0.5rem;
            left: 0;
            box-shadow: 0 2px 4px rgb(141, 141, 141);
            border-top-right-radius:0.5rem ;
            border-bottom-right-radius:0.5rem ;
            cursor: pointer;
        }
    }

}
@media (min-width:999px) {
    .SideBarHolder{
        position: relative!important;
        width: 25%!important;
        transform: translateX(0)!important;
        opacity: 1!important;
    }
    .ContentHolder{
        margin-top: 0!important;
        .OpenSideBar{
            display: none!important;
        }
    }
    
}