.ListHolder{
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li{
        margin: 0.5rem;
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img{
            width: 100%;
            height: 140px;
            object-fit: cover;
        }
        .VideoHolder{
            width: 100%;
            height: 140px;
            object-fit: cover;
        }
        p,button{
            font-size: 0.9rem;
            margin: 0.5rem 0;
            height: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}