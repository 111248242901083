.DetailHolder{
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li{
        width: 280px;
        margin: 0.5rem ;
        display: flex;
        border-bottom: 1px dashed gold;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 0.5rem;
        p,h6{
            margin: 0;
        }
    }

}