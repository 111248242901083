.FormHolder{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    box-sizing: border-box;
    padding: 1rem;
    .InputHolder{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0;
        li{
            width: 260px;
            margin: 0.5rem;
            p{
                font-size: 0.8rem;
            }
            .CheckBoxHolder{
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: center;
            }
        }

    }}