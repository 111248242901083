.ListHolder{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    box-sizing: border-box;
    padding: 0.5rem;
}
.CurrencyHistoryPart{
    width: 100%;
    box-shadow: 0 2px 3px rgb(139, 139, 139);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0.7rem;
    background: #ffff;
    margin-bottom: 0.7rem;
    .DivisonPart{
        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .titlePart{
            color: goldenrod;
        }
    }
}