.PropertyDetailsHolder{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .description{
        width: 100%;
        text-align: right;
        span{
            color: red;
        }
    }
    .ImageHolder{
        width: 70%;
        height: 25rem;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .PropertyInfoHolder{
        width: 100%;
        margin: 0.5rem;
        padding: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        
        li{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 18rem;
            margin: 0.3rem;
            background: rgb(235, 235, 235);
            box-sizing: border-box;
            padding: 0.3rem;
            h5{
                font-size: 1.1rem;
                color: goldenrod;
            }
            p{
                margin: 0;
            }
        }
    }
}