.formHolder{
    height: 600px;
    width: 90%;
    background: rgb(255, 255, 255);
    border: 4px solid;
    border-image: linear-gradient(135deg,
    #FF0000 0%,
    #FFFF00 25%,
    #ADD8E6 50%,
    #00FF00 75%,
    #FF00A8 100%)1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top:5%;
    .logoHolder
    {
        width:150px;
        height: 150px;
        margin-bottom: 1rem;
        img{
            height: 100%;
            width: 100%;
            
        }
    }
    .InputHolder{
        width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 2rem 0;
        height: 6rem;
    }
}
